<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import io from "socket.io-client";
import dailyTransactions from "../../../mixins/dailyCalculationsMixin"

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [dailyTransactions],
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Admin",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "customerName", sortable: true, label: "Customer Name" },
        {
          key: "transactionData.changes.transactionDate",
          sortable: true,
          label: "Transaction Date",
        },
        {
          key: "transactionData.changes.transactionAmount",
          sortable: true,
          label: "Transaction Amount (GHS)",
        },
        {
          key: "agentName",
          sortable: true,
          label: "Agent Name",
        },
        {
          key: "transactionData.changes.paymentTypeId",
          sortable: true,
          label: "Payment Type",
        },
        { key: "transactionType" },
        { key: "transactionPaymentType", label: "Individual / Group" },
        { key: "createdAt", label: "Timestamp" },
        { key: "notified" },
      ],
    };
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.todaysTransactions.length;
    }, 
  },
  created() {
    const socket = io(process.env.VUE_APP_SOCKET_URL);

    socket.on("makeDeposit", (newData) => {
      console.log(newData);
      this.todaysTransactions.unshift(newData);
    });

    socket.on("withdrawal", (newData) => {
      console.log(newData);
      this.todaysTransactions.unshift(newData);
    });

    socket.on("loanRepayment", (newData) => {
      console.log(newData);
      this.todaysTransactions.unshift(newData);
    });
  },

  mounted() {
    this.getAgents();
    this.getTodaysTransactions();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="loading">
      <b-spinner class="m-2 m-auto" variant="primary" role="status"></b-spinner>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12">
        <div>
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="text-center mb-5">
                <!-- <img
                  src="../../../assets/ppmc-logo.png"
                  class="mb-2"
                  alt
                  height="45"
                /> -->
                <h4>Welcome ! Xefe</h4>
                <p class="text-muted mb-4">
                  Hello , view data metrics about the application here.
                </p>
              </div>
            </div>
          </div>
          <!-- end row -->
          <div class="row">
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Number of Agents
                      </p>
                      <h4 class="mb-0">{{ agents.length }}</h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-user-2-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      {{ agents.length }}
                    </span>
                    <span class="text-muted ml-2">Number of Agents</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Number of Transactions Today
                      </p>
                      <h4 class="mb-0">{{ todaysTransactions.length }}</h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-admin-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      {{ todaysTransactions.length }}
                    </span>
                    <span class="text-muted ml-2"
                      >Number of Transactions Today</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Total loans collected today
                      </p>
                      <h4 class="mb-0">GHS {{ calculateTransactions.toLocaleString() }}</h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-wallet-3-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      GHS {{ calculateTransactions.toLocaleString() }}
                    </span>
                    <span class="text-muted ml-2"
                      >Total loans collected today</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Momo Loan Payments Today
                      </p>
                      <h4 class="mb-0">
                        GHS {{ momoTransactions.toLocaleString() }}
                      </h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-cash-fill font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      GHS {{ momoTransactions.toLocaleString() }}
                    </span>
                    <span class="text-muted ml-2">Momo</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Cash Loan Payments Today
                      </p>
                      <h4 class="mb-0">
                        GHS {{ cashTransactions.toLocaleString() }}
                      </h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-cash-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      GHS {{ cashTransactions.toLocaleString() }}
                    </span>
                    <span class="text-muted ml-2">Cash</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Withdrawals Today
                      </p>
                      <h4 class="mb-0">GHS {{ cashWithdrawals.toLocaleString() }}</h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-cash-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      GHS {{ cashWithdrawals.toLocaleString() }}
                    </span>
                    <span class="text-muted ml-2">Cash</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Cash Collaterals / Deposits Today
                      </p>
                      <h4 class="mb-0">GHS {{ cashCollaterals.toLocaleString() }}</h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-cash-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      GHS {{ cashCollaterals.toLocaleString() }}
                    </span>
                    <span class="text-muted ml-2">Cash Collateral</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card">
          <div class="card-header bg-transparent border-bottom">
            <h5 class="mb-0">Filters</h5>
          </div>

          <div class="card-body">
            <h5 class="font-size-14 mb-3">Filter By :</h5>

            <div
              id="accordion"
              class="custom-accordion categories-accordion mb-3"
            >
              <div class="categories-group-card">
                <a v-b-toggle.electorinic class="categories-group-list">
                  <i
                    class="mdi mdi-account-star-outline font-size-16 align-middle mr-2"
                  ></i>
                  Customer Name
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse
                  id="electorinic"
                  data-parent="#accordion"
                  accordion="my-accordion"
                >
                  <div class="search-box ml-2 mt-4 mb-4">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control rounded"
                        v-model="searchQuery"
                        placeholder="Customer Name..."
                      />
                      <i class="mdi mdi-magnify search-icon"></i>
                    </div>
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a
                  v-b-toggle.trans
                  class="categories-group-list"
                  data-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <i
                    class="mdi mdi-account-group-outline font-size-16 align-middle mr-2"
                  ></i>
                  Individual/Group
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>
                <b-collapse
                  id="trans"
                  visible
                  data-parent="#accordion"
                  accordion="my-accordion"
                >
                  <div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck7"
                        v-model="individualOrGroup"
                        value="Individual"
                      />
                      <label class="custom-control-label" for="customCheck7"
                        >Individual</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck6"
                        value="Group"
                        v-model="individualOrGroup"
                      />
                      <label class="custom-control-label" for="customCheck6"
                        >Group</label
                      >
                    </div>
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a
                  v-b-toggle.fashion
                  class="categories-group-list"
                  data-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <i class="mdi mdi-cash font-size-16 align-middle mr-2"></i>
                  Transaction Type
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>
                <b-collapse
                  id="fashion"
                  visible
                  data-parent="#accordion"
                  accordion="my-accordion"
                >
                  <div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                        v-model="selectedValues"
                        value="SavingsDeposit"
                      />
                      <label class="custom-control-label" for="customCheck1"
                        >Cash Collateral</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck2"
                        value="LoanPayment"
                        v-model="selectedValues"
                      />
                      <label class="custom-control-label" for="customCheck2"
                        >Loan Payment</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck8"
                        value="Withdrawal"
                        v-model="selectedValues"
                      />
                      <label class="custom-control-label" for="customCheck8"
                        >Withdrawal</label
                      >
                    </div>
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a
                  v-b-toggle.kids
                  class="categories-group-list collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <i
                    class="mdi mdi-account-cash-outline font-size-16 align-middle mr-2"
                  ></i>
                  Payment Type
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>
                <b-collapse
                  id="kids"
                  data-parent="#accordion"
                  accordion="my-accordion"
                >
                  <div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck3"
                        v-model="paymentType"
                        value="1"
                      />
                      <label class="custom-control-label" for="customCheck3"
                        >Cash Payment</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        v-model="paymentType"
                        class="custom-control-input"
                        id="customCheck4"
                        value="2"
                      />
                      <label class="custom-control-label" for="customCheck4"
                        >Momo Payment</label
                      >
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Todays Transactions Table</h4>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 sticky-header-table">
              <b-table
                :items="filterCustomer"
                :fields="fields"
                responsive="sm"
                stacked="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template
                  v-slot:cell(transactionData.changes.paymentTypeId)="row"
                >
                  <div
                    v-if="row.value == 1"
                    class="badge badge-soft-success font-size-12"
                  >
                    Cash Payment
                  </div>
                  <div
                    v-if="row.value == 2"
                    class="badge badge-soft-warning font-size-12"
                  >
                    Momo Payment
                  </div>
                </template>
                <template v-slot:cell(transactionType)="row">
                  <div
                    v-if="row.value == 'LoanPayment'"
                    class="badge badge-success font-size-12"
                  >
                    Loan Payment
                  </div>
                  <div
                    v-if="row.value == 'SavingsDeposit'"
                    class="badge badge-warning font-size-12"
                  >
                    Cash Collateral
                  </div>
                  <div
                    v-if="row.value == 'Withdrawal'"
                    class="badge badge-danger font-size-12"
                  >
                    Withdrawal
                  </div>
                </template>
                <template v-slot:cell(notified)="row">
                  <div
                    v-if="row.value == true"
                    class="badge badge-soft-success font-size-12"
                  >
                    Message Sent
                  </div>
                  <div
                    v-if="row.value == false"
                    class="badge badge-soft-danger font-size-12"
                  >
                    Failed
                  </div>
                </template>
                <template v-slot:cell(createdAt)="row">
                  <div class="badge badge-soft-info font-size-12">
                    {{ formatTime(row.value) }}
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
